.container{
    display: flex;
    margin: 0px;
    width: 100%;
    margin-top: 20px;
}
.ShoppingContainer
{
   left: 50%;
   margin-bottom: 20px;
}
.header {
  margin-top: 20px;
  margin-left: 40px;
}
.main-content {
  margin-top: 2rem;
}

.product {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem 0;
  margin: 1rem 0;
}
.product-detail {
  padding: 0 2rem;
}
.product-price {
  font-weight: bold;
  font-size: 140%;
}
.space
{
  margin-left: 10px;
  margin-top: 5px;
  height: 40px;
}
.icon
{
   margin-left:15px;
   margin-right:5px;
   cursor: pointer;
   text-decoration: none;
   color: black;
}
.ProductImg{
  width: 350px;
  height: 350px;
  margin-top: 5px;
}
.newDeliveryItem1
{
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    margin-right: 20px;
}
.newDeliveryItem1>label{
    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    color:rgb(125, 125, 125);
}
.newDeliveryItem1>input{
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}
.DeliveryWidgetClient1{
display: flex;
flex-wrap: wrap;
}