.ProductImg-Name{
    width: 350px;
    height: 350px;
    margin-top: 5px;
}
.newProductItem-1-button
{ 
    text-align: center;
    margin-top: 70px;
    margin-bottom: 20px;
}
.select{
    height: 40px;
    width: 100%;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
}
.DistributorContainer
{
    margin-left: 90px;
    margin-top: 20px;
    margin-right: 20px;
    
}
.ProductImg-Listing{
    width: 120px;
    height: 120px;
    left: 0;
    cursor: pointer;
}
.ProductItem{
    padding: 25px;
    border-radius: 10px;
    cursor: pointer;
    Color:rgb(0, 0, 0);
    box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    background-color:  rgb(255, 255, 255);
}
.table
{
    margin-left: 200px;
}
.icon
{
    margin-top: 2px;
   margin-left:15px;
   margin-right:10px;
   cursor: pointer;
}
.deleteicon
{
    margin-left:5px;
    cursor: pointer;
}
.ProductImg-Listing1{
    width: 120px;
    height: 80px;
    left: 0;
    cursor: pointer;
}
.link-menu1
{
    cursor: pointer;
  text-decoration: none;
  color: black;
    margin-left: 15px;
}