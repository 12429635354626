.link
{
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.link-menu{
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.appbarColor
{
    background-color: rgb(64,64,64);
}
.ShoppingContainer
{
    margin-left: 90px;
    margin-top: 20px;
    margin-top: 20px;
}
.ProductImg{
    width: 350px;
    height: 350px;
    margin-top: 5px;
}
.newProductItem-1-button
{ 
    text-align: center;
    margin-top: 70px;
    margin-bottom: 20px;
}
.select{
    height: 40px;
    width: 100%;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
}
.DistributorContainer
{
    margin-left: 90px;
    margin-top: 20px;
    margin-top: 20px;
}
.ProductImg-Listing{
    width: 120px;
    height: 80px;
    margin-top: 5px;
    margin-left: 20%;
}
.ProductItem{
    padding: 25px;
    border-radius: 10px;
    cursor: pointer;
    Color:rgb(0, 0, 0);
    box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    background-color:  rgb(255, 255, 255);
}
.table
{
    margin-left: 200px;
}
.shopping-icon
{
   margin-right:10px;
   cursor: pointer;
}
