.Container
{
    margin-left: 20px;
    margin-top: 85px;
}
.SalesContainer-1
{
  margin-left: 85px;
  margin-top: 80px;
}
.SalesContainer
{
  margin-left: 85px;
  margin-top: 85px;
  margin-right: 20px;
}
.Container-Group{
  margin-left: 85px;
    margin-top: 85px;
    margin-right: 20px;
}
.distributorItem{
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    Color:rgb(0, 0, 0);
    background-color:  rgb(255, 255, 255);
}
.distributorItem0{
  padding: 25px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: -10px;
  border-radius: 10px;
}
.userShowUsername-Edit{
  font-weight: 600;
  font-size: x-large;
  
}
.userShowTop {
    display: flex;
    align-items: center;
    
  }
  
  .userShowImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .userShowTopTitle {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  
  .userShowUsername {
    font-weight: 600;
  }
  
  .userShowUserTitle {
    font-weight: 300;
  }
  
  .userShowBottom{
      margin-top: 10px;
  }
  
  .userShowTitle {
    font-size: 16px;
    font-weight: 600;
    color: rgb(175, 170, 170);
  }
  
  .userShowInfo{
      display: flex;
      align-items: center;
      margin: 10px 0px;
      color: #444;
  }
  
  .userShowIcon{
      font-size: 16px !important;
  }
  .userShowInfoTitle{
    margin-left: 10px;
}