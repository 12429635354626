.link
{
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.link-menu{
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.appbarColor
{
    background-color: rgb(64,64,64);
}
.shopping-icon{
    cursor: pointer;
}
.shopping-Avatar{
padding: 5px;
margin-left: 60px;
}
.mybadge{
    width: 20px;
    height: 20px;
   cursor: pointer;
   margin-bottom: 1px;
}