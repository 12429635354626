.SetupContainer{
    margin-left: 90px;
    margin-top: 20px;
}
  .SetupminiHeader{
    color: rgb(49, 109, 239);
    margin-bottom: 10px;
}

    .Globalselect
  {
    height:48px;
    margin-top: 30px;
  }
  .adminList{
    margin-right: 20px;
    margin-bottom: 20px;
}
.newDeliveryItem
  {
      width: 300px;
      display: flex;
      flex-direction: column;
      margin-top: -15px;
      margin-right: 20px;
  }
  .newDeliveryItem>label{
      margin-bottom: 0px;
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
      color:rgb(125, 125, 125);
  }
  
  .newDeliveryItem>input{
      padding: 10px;
      
      border: 1px solid gray;
      border-radius: 5px;
  }
  .newDeliveryItem>select{
    height: 45px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}
.DeliveryWidgetClient{
  display: flex;
  flex-wrap: wrap;
}
