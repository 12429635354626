.DistributorContainer{
    margin-left: 90px;
    margin-top: 20px;
  }
  .DeliveryWidgetClient-sub
  {
    display: flex;
    margin-bottom: 5px;
    margin-top: 15px;
    color:rgb(32, 84, 255);
  }
  .newDeliveryItem1
  {
      width: 300px;
      display: flex;
      flex-direction: column;
      margin-top: -15px;
      margin-right: 20px;
  }
  .newDeliveryItem1>label{
      margin-bottom: 0px;
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
      color:rgb(125, 125, 125);
  }
  .newDeliveryItem1>input{
      padding: 10px;
      border: 1px solid gray;
      border-radius: 5px;
  }
.DeliveryWidgetClient1{
  display: flex;
  flex-wrap: wrap;
}
.autocomplete1{
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 300px;
  height: 47px;
  margin-top: 2px;
}
.datepicker
{
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 300px;
}