.container{
    display: flex;
    margin: 0px;
  }
  .DistributorContainer{
    margin-left: 90px;
    margin-top: 20px;
    margin-right: 20px;
  }
  .DistributorNo
  {
    margin-top: 10px;
  }
  .miniHeader{
    color: rgb(49, 109, 239);
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .Disselect
  {
    height:48px;
  }
  .newDeliveryItem1
  {
      width: 300px;
      display: flex;
      flex-direction: column;
      margin-top: -15px;
      margin-right: 20px;
  }
  .newDeliveryItem1>label{
      margin-bottom: 0px;
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
      color:rgb(125, 125, 125);
  }
  .newDeliveryItem1>input{
      padding: 10px;
      border: 1px solid gray;
      border-radius: 5px;
  }
.DeliveryWidgetClient1{
  display: flex;
  flex-wrap: wrap;
}