.link
{
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.link-menu{
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.appbarColor
{
    background-color: rgb(64,64,64);
}
.shopping-icon{
    margin-left: -10px;
    cursor: pointer;
    margin-top: -7px;
}
.shopping-Avatar{
padding: 5px;
}
