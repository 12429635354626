.SalesContainer{
    margin-left: 90px;
    margin-top: -10px;
    margin-right: 20px;
  }
  .SalesContainer-0{
    margin-left: 90px;
    margin-right: 20px;
  }
  .split {
    margin-top: 20px;
    margin-left: 90px;
      height: 100%;
      width: 33.33%;
      position: fixed;
      z-index: 1;
      top: 0;
      overflow-x: hidden;
      padding-top: 20px;
    }
    .fontfamily
    {
      font-size: 15pt;
      text-align: justify;
    }
    .left {
      left: 0;
      width:30%;
      background-color: white;
    }
    .center{
      left: 30%;
      width:40%;
    }
    .right {
      right: 0;
      width:30%;
      background-color: rgb(219, 219, 219);
    }
    .centered {
      margin-top: 30px;
      margin-left: 20px;
    }
    .paymentOption
    {
      margin-top: 10px;
      margin-left: 10px;
    }
    .CartContainer
    {
      margin-left: 90px;
      margin-top: 20px;
    }
    .ProductImg-0
    {
      width: 200px;
      height: 200px;
    }
    .Payment
    {
      left: 50%;
      top: 50%;
    }
    .ProductImg-Shop{
      width: 150px;
      height: 150px;
      margin-top: 5px;
    }
    .newDeliveryItem
    {
        width: 300px;
        display: flex;
        flex-direction: column;
        margin-top: -5px;
        margin-right: 20px;
    }
    .newDeliveryItem>label{
        margin-bottom: 0px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        color:rgb(125, 125, 125);
    }
    .newDeliveryItem>input{
        padding: 10px;
        border: 1px solid gray;
        border-radius: 5px;
    }
  .DeliveryWidgetClient{
    display: flex;
    flex-wrap: wrap;
}
.newOrderItem1
{
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    margin-right: 20px;
}
.OrderWidgetClient1{
display: flex;
flex-wrap: wrap;
}
.Delivery{
  flex: 4;
}
.link-menu{
  cursor: pointer;
  text-decoration: none;
  color: black;
  margin-left: 0px;
}