.CouponContainer{
    margin-left: 90px;
    margin-top: 20px;
    margin-right: 20px;
  }

.CreateCoupon{
  flex: 4;
}
.CreateCouponWidgetClient{
  display: flex;
  margin: 20px 90px;
}
.CreateCouponClient{
  display: flex;
  margin: 20px 40px;
  color:rgb(125, 125, 125);
}
.datepicker
{
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 300px;
}
.newDeliveryItem1
  {
      width: 300px;
      display: flex;
      flex-direction: column;
      margin-top: -15px;
      margin-right: 20px;
  }
  .newDeliveryItem1>label{
      margin-bottom: 0px;
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
      color:rgb(125, 125, 125);
  }
  .newDeliveryItem1>input{
      padding: 10px;
      border: 1px solid gray;
      border-radius: 5px;
  }
.DeliveryWidgetClient1{
  display: flex;
  flex-wrap: wrap;
}
.autocomplete{
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 300px;
  height: 47px;
  margin-top: 2px;
}