.FeatureContainer
{
    margin-left: 90px;
    margin-top: 20px;
}
.featuredItem{
    padding: 25px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    -webkit-box-shadow: 0px 0px 15px -10px rgba(8,0,0,0.47);
    Color:White;
    background-color:  rgb(104, 103, 103);
}
.featuredTitle{
    font-size: 20px;
    font-weight: 600;
}
.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}
.featuredMoney{
    font-size: 30px;
    font-weight: 600;

}
.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: rgb(35, 175, 35);
}
.featuredIcon.negative{
    color: red;
}
.featureSub{
    font-size: 15px;
    color: rgb(193, 193, 193);
}