
    .fontfamily
    {
      font-size: 14pt;
      text-align: justify;
    }
    .paymentOption
    {
      margin-top: 10px;
      margin-left: 10px;
    }
    .CartContainer
    {
      margin-left: 90px;
      margin-top: 20px;
    }
    .ProductImg-0
    {
      width: 200px;
      height: 200px;
    }
    .Payment
    {
      left: 50%;
      top: 50%;
    }
    .ProductImg-Shop{
      width: 150px;
      height: 150px;
      margin-top: 5px;
    }
    .newOrderItem1
{
    width: 420px;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    margin-right: 20px;
}
.OrderWidgetClient1{
display: flex;
flex-wrap: wrap;
}